import { getStaticAssetUrl } from './utils/get-static-asset-url';

export default {
  LEGACY_vertical: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/default/vertical.png'),
  LEGACY_vertical_no_image: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/default/vertical_no_image.png'),
  LEGACY_vertical_tt_d: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/default/vertical_tt_d.png'),
  LEGACY_horizontal: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/horizontal/horizontal.png'),
  LEGACY_horizontal_no_image: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal/horizontal_no_image.png',
  ),
  LEGACY_horizontal_tt_d: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/horizontal/horizontal_tt_d.png'),
  LEGACY_horizontal_fb: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal-full-width/horizontal_fb.png',
  ),
  LEGACY_horizontal_fb_no_image: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal-full-width/horizontal_fb_no_image.png',
  ),
  LEGACY_horizontal_fb_tt_d: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/horizontal-full-width/horizontal_fb_tt_d.png',
  ),
  LEGACY_vertical_fb: getStaticAssetUrl('preset-thumbnails/legacy-single-plan/vertical-full-width/vertical_fb.png'),
  LEGACY_vertical_fb_no_image: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/vertical-full-width/vertical_fb_no_image.png',
  ),
  LEGACY_vertical_fb_tt_d: getStaticAssetUrl(
    'preset-thumbnails/legacy-single-plan/vertical-full-width/vertical_fb_tt_d.png',
  ),
  plan_list_horizontal: getStaticAssetUrl('preset-thumbnails/plan-list/horizontal.png'),
  plan_list_horizontal_fb: getStaticAssetUrl('preset-thumbnails/plan-list/horizontal_fb.png'),
  plan_list_horizontal_fb_no_image: getStaticAssetUrl('preset-thumbnails/plan-list/horizontal_fb_no_image.png'),
  plan_list_horizontal_fb_tt_d: getStaticAssetUrl('preset-thumbnails/plan-list/horizontal_fb_tt_d.png'),
  plan_list_horizontal_no_image: getStaticAssetUrl('preset-thumbnails/plan-list/horizontal_no_image.png'),
  plan_list_horizontal_tt_d: getStaticAssetUrl('preset-thumbnails/plan-list/horizontal_tt_d.png'),
  plan_list_vertical: getStaticAssetUrl('preset-thumbnails/plan-list/vertical.png'),
  plan_list_vertical_fb: getStaticAssetUrl('preset-thumbnails/plan-list/vertical_fb.png'),
  plan_list_vertical_fb_no_image: getStaticAssetUrl('preset-thumbnails/plan-list/vertical_fb_no_image.png'),
  plan_list_vertical_fb_tt_d: getStaticAssetUrl('preset-thumbnails/plan-list/vertical_fb_tt_d.png'),
  plan_list_vertical_no_image: getStaticAssetUrl('preset-thumbnails/plan-list/vertical_no_image.png'),
  plan_list_vertical_tt_d: getStaticAssetUrl('preset-thumbnails/plan-list/vertical_tt_d.png'),
  single_plan_horizontal: getStaticAssetUrl('preset-thumbnails/single-plan/horizontal.png'),
  single_plan_horizontal_fb: getStaticAssetUrl('preset-thumbnails/single-plan/horizontal_fb.png'),
  single_plan_horizontal_fb_no_image: getStaticAssetUrl('preset-thumbnails/single-plan/horizontal_fb_no_image.png'),
  single_plan_horizontal_fb_tt_d: getStaticAssetUrl('preset-thumbnails/single-plan/horizontal_fb_tt_d.png'),
  single_plan_horizontal_no_image: getStaticAssetUrl('preset-thumbnails/single-plan/horizontal_no_image.png'),
  single_plan_horizontal_tt_d: getStaticAssetUrl('preset-thumbnails/single-plan/horizontal_tt_d.png'),
  single_plan_vertical: getStaticAssetUrl('preset-thumbnails/single-plan/vertical.png'),
  single_plan_vertical_fb: getStaticAssetUrl('preset-thumbnails/single-plan/vertical_fb.png'),
  single_plan_vertical_fb_no_image: getStaticAssetUrl('preset-thumbnails/single-plan/vertical_fb_no_image.png'),
  single_plan_vertical_fb_tt_d: getStaticAssetUrl('preset-thumbnails/single-plan/vertical_fb_tt_d.png'),
  single_plan_vertical_no_image: getStaticAssetUrl('preset-thumbnails/single-plan/vertical_no_image.png'),
  single_plan_vertical_tt_d: getStaticAssetUrl('preset-thumbnails/single-plan/vertical_tt_d.png'),
};
